import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  headingDark: {
    color: "#FFF",
  },
}));

const Accordion = withStyles({
  root: {
    borderTop: "none",
    borderRadius: 10,
    boxShadow: "none",
    marginBottom: "1em",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginBottom: "1em",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.medium,
    borderRadius: 10,
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      backgroundColor: theme.palette.primary.dark,
      color: "#FFF",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  content: {
    "&$expanded": {
      margin: "2px 0",
      backgroundColor: theme.palette.primary.dark,
      color: "#FFF",
    },
    borderRadius: 10,
    flexGrow: 1,
    justifyContent: "flex-start",
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.dark,
    paddingTop: 0,
    color: "#FFF",
    "&$expanded": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}))(MuiAccordionDetails);

export default function SimpleAccordion({ expandedDark }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(["panel1"]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? [panel] : expanded.filter((p) => p !== panel));
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded.includes("panel1")}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ color: expanded.includes("panel1") ? "#FFF" : "#000" }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Typography
            className={clsx(classes.heading, {
              [classes.headingDark]:
                expandedDark && expanded.includes("panel1"),
            })}
          >
            Road Manager
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderTopLeftRadius: expanded.includes("panel1") ? 0 : 10,
            borderTopRightRadius: expanded.includes("panel1") ? 0 : 10,
          }}
        >
          <Typography style={{ margin: "0 auto" }}>
            This integrated solution was designed to optimize field
            interventions, while facilitating a day-to-day HOV lane monitoring
            through a reliable performance and a quality control intuitive
            interface.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("panel2")}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ color: expanded.includes("panel2") ? "#FFF" : "#000" }}
            />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Typography
            className={clsx(classes.heading, {
              [classes.headingDark]:
                expandedDark && expanded.includes("panel2"),
            })}
          >
            Integrator
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderTopLeftRadius: expanded.includes("panel2") ? 0 : 10,
            borderTopRightRadius: expanded.includes("panel2") ? 0 : 10,
          }}
        >
          <Typography>
            The totem device is compact and easy to install on constrained
            conditions. The qualification tool makes it easy to evaluate the
            overall system performance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("panel3")}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ color: expanded.includes("panel3") ? "#FFF" : "#000" }}
            />
          }
          aria-controls="panel3a-content"
          id="panel3a-header"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Typography
            className={clsx(classes.heading, {
              [classes.headingDark]:
                expandedDark && expanded.includes("panel3"),
            })}
          >
            Engineering Firm
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderTopLeftRadius: expanded.includes("panel3") ? 0 : 10,
            borderTopRightRadius: expanded.includes("panel3") ? 0 : 10,
          }}
        >
          <Typography>
            Thanks to its modularity, the system can be easily adapted to the
            needs of each mobility organizing authority and new functionalities
            can be added progressively.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
